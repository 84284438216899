import React from "react";
import { string } from "prop-types";
import { publicUserPropType, trackingDataProp } from "../../../lib/graphql/propTypes";
import { Flex, Text } from "../../ui";
import { noScoresForThisUser } from "../../utils/strings/dynamic";
import PersonalityResults from "../Dashboard/16Types/PersonalityResults";
import CulturePulseResults from "../Dashboard/CulturePulse/CulturePulseResults";
import DiscResults from "../Dashboard/DISC/DiscResults";
import DrivingForcesResults from "../Dashboard/DrivingForces/DrivingForcesResults";
import EnergyRhythmResults from "../Dashboard/EnergyRhythm/EnergyRhythmResults";
import EnneagramResults from "../Dashboard/Enneagram/EnneagramResults";
import InstinctiveDrivesResultsContainer from "../Dashboard/InstinctiveDrives/InstinctiveDrivesResultsContainer";
import MotivatingValuesResults from "../Dashboard/MotivatingValues/MotivatingValuesResults";
import StrengthscopeResults from "../Dashboard/Strengthscope/StrengthscopeResults";
import StrengthsFinderResults from "../Dashboard/StrengthsFinder/StrengthsFinderResults";
import ViaResults from "../Dashboard/VIA/ViaResults";

const scoreViewComponent = (type, score, user, trackingData) => {
  if (!score) {
    return <Text center>{noScoresForThisUser(user.fullName)}</Text>;
  }

  switch (type) {
    case "strengths":
      return <StrengthsFinderResults data={score} trackingData={trackingData} />;

    case "via":
      return <ViaResults data={score} trackingData={trackingData} />;

    case "personality":
      return <PersonalityResults data={score} trackingData={trackingData} />;

    case "culture":
      return <CulturePulseResults data={score} trackingData={trackingData} />;

    case "motivatingvalues":
      return <MotivatingValuesResults data={score} trackingData={trackingData} />;

    case "disc":
      return <DiscResults data={{ user }} trackingData={trackingData} />;

    case "drivingforces":
      return <DrivingForcesResults data={score} trackingData={trackingData} />;

    case "enneagram":
      return <EnneagramResults data={score} trackingData={trackingData} />;

    case "instinctiveDrives":
      return <InstinctiveDrivesResultsContainer data={score} trackingData={trackingData} />;

    case "energyRhythm":
      return <EnergyRhythmResults data={score} trackingData={trackingData} />;

    case "strengthscope":
      return <StrengthscopeResults data={score} trackingData={trackingData} />;

    default: break;
  }

  return null;
};

export default class ScoreView extends React.PureComponent {
  static propTypes = {
    user: publicUserPropType.isRequired,
    selectedScoreType: string.isRequired,
    trackingData: trackingDataProp,
  }

  static defaultProps= {
    trackingData: undefined,
  }

  render() {
    const { user, selectedScoreType, trackingData } = this.props;

    const score = user.scores[selectedScoreType];

    return (
      <Flex w={1} column>
        {scoreViewComponent(selectedScoreType, score, user, trackingData)}
      </Flex>
    );
  }
}
