import React from "react";
import {
  func,
  string,
  shape
} from "prop-types";
import {
  Flex,
  Box,
  Logo,
  TextLabel,
  Button
} from "../ui";
import { useLocale } from "@/lib/hooks";

function UnauthorizedProfilePrompt({ user, onClick }) {
  const { t } = useLocale();

  return (
    <Flex p={2} justify="center" align="center" column>
      <Box mb={2} w={1 / 2}>
        <Logo />
      </Box>
      <Box>
        <TextLabel extraLarge>
          {t("unauthorized-profile.heading")}
        </TextLabel>
      </Box>
      <Box mt={2}>
        <TextLabel>
          {user?.name ?
            t("unauthorized-profile.message-with-user", { user: user.name })
            : t("unauthorized-profile.message")}
        </TextLabel>
      </Box>
      <Box mt={2}>
        <Button block primary onClick={onClick}>
          {t("unauthorized-profile.start-a-team")}
        </Button>
      </Box>
    </Flex>
  );
}

UnauthorizedProfilePrompt.propTypes = {
  onClick: func,
  user: shape({
    name: string,
  }),
};

export default UnauthorizedProfilePrompt;
